.main-wrapper {
  background-color: #fff;
  position: absolute;
  height: 100vh;
  width: 50px;
  z-index: 100;

  .menu-main-wrapper {
    background-color: #fff;
    position: absolute;
    top: 50px;
    left: 50px;
    height: 150px;
    width: 100px;
    padding: 20px 10px;
    box-sizing: border-box;
    transition: .5s;

    &.open {
      height: 100vh;
      top: 0;
      width: 300px;

      display: flex;
      align-items: center;
      justify-content: center;

      .menu-inner-wrapper {
        width: 100%;

        .logo-wrapper {
          width: 80px;
          margin: 0 auto;
        }

        .btn-menu {}

        .menu-wrapper {
          margin-top: 50px;

          .menu-button-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .icon-wrapper {
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .text {
              font-size: 20px;
              margin-left: 20px;

              color: #636363;

              &:hover {
                color: #f17759;
              }
            }

            &.active {
              .text {
                color: #f17759;
              }
            }
          }

          .languages-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #636363;

            .language-button {
              margin: 10px;

              &.active {
                color: #f17759;
                cursor: default;
              }
            }
          }
        }

        .btn-menu-close {
          position: absolute;
          top: 0;
          right: 10px;
          bottom: 0;
          margin: auto;
          width: 30px;
          height: 30px;
        }
      }
    }


    .menu-inner-wrapper {

      .logo-wrapper {}

      .btn-menu {
        margin-top: 20px;

        &.hide {
          display: none;
        }

        img {
          width: 50px;
        }
      }

      .menu-wrapper {
        &.hide {
          display: none;
        }
      }

      .btn-menu-close {
        &.hide {
          display: none;
        }
      }
    }
  }

  .title {
    transform: rotate(270deg);
    transform-origin: 0 0;
    text-align: center;
    margin: 0 auto;
    box-sizing: border-box;
    position: absolute;
    top: 100vh;
    width: 100vh;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .title-inner-wrapper {
      letter-spacing: 17px;
      padding: 0 0 0 17px;
      border-left: 5px solid #F04922;
      border-right: 5px solid #F04922;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    position: absolute;
    width: 100%;
    height: unset;
    top: 0;
    left: 0;

    .menu-main-wrapper {
      position: relative;
      top: unset;
      left: unset;
      width: 100%;
      height: 50px;
      padding: 20px 10px 10px 10px;
      margin-top: 10px;

      &.open {
        width: unset;
        height: 70vh;

        .menu-inner-wrapper {
          justify-content: center;

          .logo-wrapper {
            height: 20px;
            width: unset;
            margin: unset;
            position: fixed;
            top: 30px;
            left: 10px;
          }

          .btn-menu {}

          .menu-wrapper {

            .menu-button-wrapper {

              .icon-wrapper {}

              .text {

                &:hover {}
              }

              &.active {
                .text {}
              }
            }
          }

          .btn-menu-close {
            top: unset;
            right: 0;
            left: 0;
            bottom: 20px;
          }
        }
      }

      .menu-inner-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .logo-wrapper {
          height: 20px;
        }

        .btn-menu {
          height: 20px;
          margin-top: 0;

          img {}
        }
      }
    }

    .title {
      transform: unset;
      width: 60%;
      height: unset;
      top: calc(60px - 22px - 10px);
      left: 0;
      right: 0;

      .title-inner-wrapper {
        font-size: 16px;
        letter-spacing: 5px;
        padding: 0 0 0 5px;
      }
    }
  }
}