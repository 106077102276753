.main-wrapper {
  background-image: url("../../assets/pc_landing_bg.gif");
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  position: relative;

  .bubble-gum-wrapper {
    position: absolute;
    top: 410px;
    left: 510px;
    width: 200px;
    // animation-name: bubble-gum-keyframes;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  .enter-wrapper {
    z-index: 50;
    position: absolute;
    width: 400px;
    top: 120px;
    right: 230px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .title-wrapper {}


    .btn-enter {
      margin: 30px 10px 0;
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      background-size: contain;

      &.btn-hk {
        background-image: url("../../assets/btn-hk.png");

        &:hover {
          background-image: url("../../assets/btn-hk-hover.png");
        }
      }

      &.btn-tw {
        background-image: url("../../assets/btn-tw.png");

        &:hover {
          background-image: url("../../assets/btn-tw-hover.png");
        }
      }

    }
  }

  .bottom-item-wrapper {
    position: absolute;
    bottom: 0px;
    left: 50px;
    display: flex;
    align-items: flex-end;


    .sponsor-wrapper {
      flex: 1;
      min-width: 100px;
      margin-bottom: 40px;
      padding-left: 20px;
    }

    .fence-wrapper {
      flex: 5;
    }
  }
}

@keyframes bubble-gum-keyframes {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(3);
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    background-image: url("../../assets/m_landing_bg.gif");
    background-position: top center;

    .bubble-gum-wrapper {}

    .enter-wrapper {
      top: 40%;
      left: 0;
      right: 0;
      width: 60%;
      margin: auto;

      .title-wrapper {}

      .btn-enter {
        width: 60px;
        margin-top: 10px;

        &:hover {}
      }
    }

    .bottom-item-wrapper {
      width: 100%;
      left: 0;

      .sponsor-wrapper {
        position: fixed;
        top: 80px;
        right: 0;
        flex: unset;
        width: 30%;
        margin: 0;
        padding: 0;
      }

      .fence-wrapper {
        width: 100%;
        flex: unset;
      }
    }
  }
}