.main-wrapper {
  width: calc(100vw - 50px);
  margin-left: 50px;
  height: 100vh;
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    height: calc(100vh - 60px);
    width: 100%;
    margin-top: 60px;
    margin-left: unset;
  }
}