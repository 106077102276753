.main-wrapper {
  background-image: url("../../assets/pc_scene2_bg.jpg");
  background-repeat: no-repeat;
  width: calc(100vw - 50px);
  margin: 0 0 0 auto;
  height: 100vh;
  background-size: cover;

  &.stage-next {
    .interactive-wrapper {

      .chatbox-wrapper {

        .chatbox-inner-wrapper {

          .message-wrapper {

            .title {}

            .message {

              span {}
            }
          }

          .button {
            background-image: url("../../assets/btn-next.png");

            &:hover {
              background-image: url("../../assets/btn-next-hover.png");
            }


          }


        }
      }

      .lincoln-wrapper {

        .eye-glasses {}

        img {}
      }
    }
  }

  &.stage-ready {
    .interactive-wrapper {

      .chatbox-wrapper {

        .chatbox-inner-wrapper {

          .message-wrapper {
            padding: 60px 100px 60px 110px;

            .title {}

            .message-content {

              &.message2 {
                .intro {
                  letter-spacing: 0.2em;
                }

                .prepare-item-wrapper {
                  margin-top: 5%;
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between;

                  .item-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    flex: 1;

                    .image-wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      padding: 0px;
                      box-sizing: border-box;
                      flex: 1 0 100%;
                      height: 30px;
                    }

                    .name {
                      color: #F04922;
                      font-size: 20px;
                      width: 100%;
                      text-align: center;
                      margin-top: 10px;
                    }

                    .desc {
                      font-size: 14px;
                      text-align: center;
                    }
                  }
                }
              }

            }
          }

          .button {
            bottom: -20px;

            &:hover {
            }


          }
        }
      }

      .lincoln-wrapper {

        .eye-glasses {}

        img {}
      }
    }
  }

  .interactive-wrapper {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .chatbox-wrapper {
      height: 55%;
      display: flex;
      align-self: flex-end;
      flex: 1 0 100%;
      justify-content: flex-end;

      .chatbox-inner-wrapper {
        height: 100%;
        position: relative;

        .message-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 5% 5% 5% 15%;

          .title {
            height: 20%;
          }

          .message-content {
            color: #fdfcfc;
            font-weight: bold;
            font-family: "Adobe Fan Heiti Std";
            margin-top: 10px;

            &.message {
              letter-spacing: 8px;
              line-height: 1.2em;
              font-size: 30px;

              span {
                color: #F04922;
              }
            }

          }

          .button {
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            background-size: contain;
            position: absolute;
            bottom: 0%;
            right: 20%;

            &.btn-next {

              &:hover {
              }
            }

            &.btn-ready {

              &:hover {
              }
            }

          }
        }

      }
    }

    .lincoln-wrapper {
      position: relative;
      height: 40%;
      display: flex;
      flex: 1 0 100%;
      align-self: flex-end;
      justify-content: center;

      .eye-glasses {
        position: absolute;
        top: 16.3%;
        width: 40px;
        left: 0;
        right: 0;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }


  }
}

@media screen and (max-width: 1024px) {
  .main-wrapper {

    &.stage-next {
      .interactive-wrapper {

        .chatbox-wrapper {

          .chatbox-inner-wrapper {

            .message-wrapper {

              .title {}

              .message {

                span {}
              }
            }

            .button {

              &:hover {}


            }


          }
        }

        .lincoln-wrapper {

          .eye-glasses {}

          img {}
        }
      }
    }

    &.stage-ready {
      .interactive-wrapper {

        .chatbox-wrapper {

          .chatbox-inner-wrapper {

            .message-wrapper {
              height: 80%;
              padding: 15% 15% 5% 15%;

              .title {}

              .message-content {

                &.message2 {
                  .intro {}

                  .prepare-item-wrapper {

                    .item-wrapper {

                      .image-wrapper {}

                      .name {}

                      .desc {}
                    }
                  }
                }

              }
            }

            .button {

              &:hover {}


            }
          }
        }

        .lincoln-wrapper {

          .eye-glasses {}

          img {}
        }
      }
    }

    .interactive-wrapper {

      .chatbox-wrapper {

        .chatbox-inner-wrapper {

          .message-wrapper {

            .title {}

            .message-content {

              &.message {

                span {}
              }

            }
          }

          .button {

            &.btn-next {

              &:hover {}
            }

            &.btn-ready {

              &:hover {}
            }

          }
        }
      }

      .lincoln-wrapper {

        .eye-glasses {}

        img {}
      }


    }
  }
}

@media screen and (max-width: 768px) {
  .main-wrapper {
    &.stage-next {
      .interactive-wrapper {

        .chatbox-wrapper {

          .chatbox-inner-wrapper {

            .message-wrapper {

              .title {}

              .message {

                span {}
              }
            }

            .button {

              &:hover {}


            }


          }
        }

        .lincoln-wrapper {

          .eye-glasses {}

          img {}
        }
      }
    }

    &.stage-ready {
      .interactive-wrapper {

        .chatbox-wrapper {

          .chatbox-inner-wrapper {

            .message-wrapper {

              .title {
                width: 50px;
              }

              .message-content {

                &.message2 {
                  .intro {}

                  .prepare-item-wrapper {

                    .item-wrapper {

                      .image-wrapper {}

                      .name {}

                      .desc {}
                    }
                  }
                }

              }
            }

            .button {

              &:hover {}


            }
          }
        }

        .lincoln-wrapper {

          .eye-glasses {}

          img {}
        }
      }
    }

    .interactive-wrapper {

      .chatbox-wrapper {

        .chatbox-inner-wrapper {

          .message-wrapper {

            .title {}

            .message-content {

              &.message {

                span {}
              }

            }
          }

          .button {

            &.btn-next {

              &:hover {}
            }

            &.btn-ready {

              &:hover {}
            }

          }
        }
      }

      .lincoln-wrapper {

        .eye-glasses {}

        img {}
      }


    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    background-image: url("../../assets/m_scene2_bg.jpg");
    height: calc(100vh - 60px);
    width: 100%;
    margin: unset;
    margin-top: 60px;
    background-position: top center;

    &.stage-next {
      .interactive-wrapper {

        .chatbox-wrapper {

          .chatbox-inner-wrapper {

            .message-wrapper {

              .title {}

              .message {

                span {}
              }
            }

            .button {

              &:hover {}


            }


          }
        }

        .lincoln-wrapper {

          .eye-glasses {}

          img {}
        }
      }
    }

    &.stage-ready {
      .interactive-wrapper {

        .chatbox-wrapper {

          .chatbox-inner-wrapper {

            .message-wrapper {
              padding: 40px 30px 10px 50px;
              width: 100%;

              .title {}

              .message-content {

                &.message2 {
                  .intro {
                    font-size: 14px;
                    letter-spacing: unset;
                  }

                  .prepare-item-wrapper {
                    margin-top: 15px;

                    .item-wrapper {

                      .image-wrapper {
                        height: 30px;
                      }

                      .name {
                        font-size: 16px;
                      }

                      .desc {
                        font-size: 12px;
                      }
                    }
                  }
                }

              }
            }

            .button {
              bottom: 10%;
              left: 60%;

              &:hover {}


            }
          }
        }

        .lincoln-wrapper {

          .eye-glasses {}

          img {}
        }
      }
    }

    .interactive-wrapper {
      width: 100%;

      .chatbox-wrapper {

        .chatbox-inner-wrapper {

          .message-wrapper {
            width: 100%;
            // padding: 20px 20px 20px 20px;
            height: 60%;

            .title {
              width: 30px;
            }

            .message-content {

              &.message {
                font-size: 14px;

                span {}
              }

            }

            .button {
              width: 50px;
              height: 50px;
              bottom: -10%;
              right: 30%;

              &.btn-next {

                &:hover {}
              }

              &.btn-ready {

                &:hover {}
              }

            }
          }

        }
      }

      .lincoln-wrapper {
        display: block;
        height: 30%;

        .eye-glasses {}

        img {}
      }


    }
  }
}