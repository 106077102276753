.main-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .background-wrapper {
    background-image: url("../../assets/pc_game1_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    width: 100%;
    height: 100%;
  }

  .scroll-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow-y: scroll;

    .scroll-content {
      width: 100%;
      height: 1000vh;
      font-size: 100px
    }
  }

  .person1-wrapper {
    position: absolute;
    height: 100%;
    top: 0;

    img {
      height: 100%;
    }
  }

  .person2-wrapper {
    position: absolute;
    top: 0;
    height: 100%;

    img {
      height: 100%;
    }
  }

  .bg-text-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    img {
      height: 100%;
      max-width: unset;
    }
  }

  .light-wrapper {
    position: absolute;
    top: -15%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 320px;
    height: 320px;
  }

  .btn-next-wrapper {
    position: absolute;
    width: 200px;
    top: 290px;
    right: 0;
    left: 0px;
    bottom: 0;
    margin: auto;
    height: 200px;
    opacity: 0;
    cursor: pointer;
  }

  .scroll-text-wrapper {
    position: absolute;
    bottom: 5%;
    left: 5%;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    height: calc(100vh - 60px);
    margin-top: 60px;

    .background-wrapper {
      background-image: url("../../assets/m_game1_bg.jpg");
    }

    .bg-text-wrapper {
      height: unset;
      width: 80%;
      margin: auto;
      left: 0;
      right: 0;
      top: 40%;

      img {
        max-height: unset;
        max-width: 100%;
        height: unset;
      }
    }

    .person1-wrapper {
      height: unset;
      top: unset;

      img {
        height: unset;
      }
    }

    .person2-wrapper {
      height: unset;
      top: unset;

      img {
        height: unset;
      }
    }

    .light-wrapper {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 120px;
      height: 120px;
    }

    .btn-next-wrapper {
      width: 80px;
      top: 0;
      right: 0px;
      left: 40%;
      bottom: 0;
      margin: auto;
      height: 80px;
    }

    .scroll-text-wrapper {
      bottom: 0;
      top: 0;
      margin: auto;
      height: 30%;
      right: 20px;
      left: unset;
    }
  }
}