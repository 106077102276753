.main-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .webgl-curtain {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    &.hide{
      animation-name: hide-curtain-keyframes;
      animation-duration: 3s;
      animation-fill-mode: forwards;
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    height: calc(100vh - 60px);
    margin-top: 60px;
  }
}

@keyframes hide-curtain-keyframes {
  from {
    opacity: 1
  }

  to {
    opacity: 0;
  }
}