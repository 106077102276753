.main-wrapper {
  width: calc(100vw - 50px);
  margin: 0 0 0 auto;
  height: 100vh;
  background-image: url('../../assets/book/background.jpg');
  background-size: cover;
  position: relative;

  .scroll-wrapper {
    overflow-y: auto;
    height: 100%;
    width: 100%;

    .inner-wrapper {
      width: 80%;
      padding: 50px 0;
      margin: 0 auto;
      position: relative;

      .title-wrapper {
        background-image: url('../../assets/book/title-background.svg');
        background-repeat: no-repeat;
        background-size: contain;
        font-size: 30px;
        color: #fff;
        width: 40%;
        height: 200px;
        letter-spacing: 4px;
        position: relative;

        .title-inner-wrapper {
          position: absolute;
          top: 80px;
          left: 70px;

          .sub-title {
            font-size: 35px;
            color: #e04c26;
          }
        }

      }

      .content-wrapper {
        margin-top: 50px;
        width: 100%;

        .books-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;

          .book-wrapper {
            flex: 0 0 45%;
            background-color: #fff;
            display: flex;
            align-items: center;
            margin-bottom: 50px;

            &.w-video {
              flex: 1 0 100%;
            }

            .image-wrapper {
              height: 300px;
            }

            .desc-wrapper {
              flex: 1;
              padding: 0 20px;

              .desc-inner-wrapper {

                p {
                  margin: 0;

                  span {
                    color: #e04c26;
                  }
                }
              }

            }

            .youtube-wrapper {
              margin-right: 30px;
            }
          }
        }

        .websites-wrapper {
          padding-top: 50px;
          border-top: 5px solid #fff;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .website-wrapper {
            flex: 0 0 45%;
            background-color: #fff;
            padding: 20px 50px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-bottom: 50px;

            .image-wrapper {
              width: 30px;
              height: 30px;
              flex: 0 0 30px;
            }

            .desc-wrapper {
              flex: 1;
              margin-left: 100px;

              p {
                margin: 0;
                word-break: break-all;

                span {
                  color: #e04c26;
                }

                a {
                  text-decoration: revert;
                }
              }
            }
          }
        }

        .videos-wrapper {
          padding-top: 50px;
          border-top: 5px solid #fff;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .video-wrapper {
            flex: 0 0 45%;
            background-color: #fff;
            padding: 20px 50px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-bottom: 50px;
            flex-wrap: wrap;

            .youtube-wrapper {
              flex: 1 0 100%;
              display: flex;
              justify-content: center;
            }

            .desc-wrapper {
              flex: 1;
              text-align: center;

              p {
                margin: 0;
                word-break: break-all;

                span {
                  color: #e04c26;
                }

                a {
                  text-decoration: revert;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .main-wrapper {

    .scroll-wrapper {

      .inner-wrapper {
        .title-wrapper {
          width: 60%;

          .title-inner-wrapper {
            top: 40px;

            .sub-title {}
          }

        }

        .content-wrapper {

          .books-wrapper {

            .book-wrapper {

              &.w-video {}

              .image-wrapper {
                height: 200px;
              }

              .desc-wrapper {

                .desc-inner-wrapper {

                  p {

                    span {}
                  }
                }
              }

              .youtube-wrapper {}
            }
          }

          .websites-wrapper {

            .website-wrapper {
              padding: 10px 30px;

              .image-wrapper {}

              .desc-wrapper {
                margin-left: 10%;

                p {

                  span {}

                  a {}
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    height: calc(100vh - 60px);
    margin: unset;
    width: 100%;
    margin-top: 60px;

    .scroll-wrapper {

      .inner-wrapper {
        width: 95%;

        .title-wrapper {
          width: 80%;
          height: 150px;
          font-size: 20px;

          .title-inner-wrapper {
            top: 40px;

            .sub-title {
              font-size: 25px;
            }
          }

        }

        .content-wrapper {

          .books-wrapper {
            display: block;

            .book-wrapper {
              flex-wrap: wrap;
              position: relative;
              flex: 0 0 80% !important;
              width: 90%;
              margin: 0 0 30px auto;

              &.w-video {}

              .image-wrapper {
                height: 150px;
                position: absolute;
                top: 10px;
                left: -10%;
              }

              .desc-wrapper {
                height: 150px;
                margin-bottom: 20px;
                padding: 0 0 0 30%;
                display: flex;
                align-items: center;

                .desc-inner-wrapper {

                  p {
                    font-size: 12px;

                    span {}
                  }
                }
              }

              .youtube-wrapper {
                margin: 0;
                flex: 1 0 100%;
                display: flex;
                justify-content: center;
              }
            }
          }

          .websites-wrapper {
            display: block;
            padding-top: 30px;

            .website-wrapper {
              margin-bottom: 30px;
              padding: 10px 30px;

              .image-wrapper {
                width: 30px;
                height: 30px;
              }

              .desc-wrapper {
                margin-left: 20px;

                p {
                  font-size: 12px;

                  span {}

                  a {}
                }
              }
            }
          }

          .videos-wrapper {

            .video-wrapper {
              padding: 10px 20px;
              flex-wrap: wrap;
              justify-content: center;
              flex: 1 0 100%;

              .youtube-wrapper {
                flex: 1 0 100%;
                display: flex;
                justify-content: center;
              }

              .desc-wrapper {
                margin-left: unset;
                text-align: center;

                p {

                  span {}

                  a {}
                }
              }
            }
          }
        }
      }
    }
  }
}