.main-wrapper {
  width: calc(100vw - 50px);
  margin: 0 0 0 auto;
  height: 100vh;
  background-image: url('../../assets/video/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .inner-wrapper {
    position: relative;
    width: 100%;

    .title-wrapper {
      background-image: url('../../assets/video/title-background.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      font-size: 30px;
      color: #fff;
      width: 30%;
      height: 200px;
      letter-spacing: 4px;
      position: relative;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30vh;

      .title-inner-wrapper {
        text-align: center;

        span {
          color: #e04c26;
        }
      }
    }

    // .video-section {
    //   overflow-x: auto;
    //   width: 100%;
    //   height: 50vh;

    //   .videos-wrapper {
    //     display: flex;

    //     .video-wrapper {
    //       display: flex;
    //       align-items: center;
    //       flex-wrap: wrap;
    //       justify-content: center;

    //       .youtube-player {
    //         width: 320px;
    //         height: 200px;
    //         background-color: #000;
    //         // border: 1px solid red;
    //         flex: 0 0 320px;
    //         margin: 0 20px;

    //       }

    //       .name {
    //         background-color: #fff;
    //         padding: 0 20px;
    //         box-sizing: border-box;
    //         display: inline-block;
    //         margin-top: 20px;
    //       }
    //     }
    //   }
    // }

    .video-section {
      height: 70vh;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .video-section-inner-wrapper {
        display: flex;
        align-items: center;

        .btn-left {
          padding: 10px;
        }

        .youtube-player {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          >div {
            flex: 1 0 100%;
            display: flex;
            justify-content: center;
          }

          .name {
            background-color: #fff;
            padding: 0 20px;
            box-sizing: border-box;
            display: inline-block;
            margin-top: 20px;
            flex: unset;
          }
        }

        .btn-right {
          padding: 10px;
        }
      }
    }

  }
}

@media screen and (max-width: 1024px) {
  .main-wrapper {

    .inner-wrapper {

      .title-wrapper {
        width: 50%;

        .title-inner-wrapper {

          span {}
        }
      }

      .video-section {

        .videos-wrapper {

          .video-wrapper {

            .youtube-player {}

            .name {}
          }
        }
      }

    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    height: calc(100vh - 60px);
    margin: unset;
    width: 100vw;
    margin-top: 60px;

    .inner-wrapper {

      .title-wrapper {
        width: 90%;
        font-size: 20px;
        height: 30vh;

        .title-inner-wrapper {

          span {}
        }
      }

      .video-section {
        height: 70vh;
        display: flex;
        align-items: flex-start;

        .video-section-inner-wrapper {
          display: flex;
          align-items: center;

          .btn-left {
            padding: 10px;
          }

          .youtube-player {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .name {
              background-color: #fff;
              padding: 0 20px;
              box-sizing: border-box;
              display: inline-block;
              margin-top: 20px;
            }
          }

          .btn-right {
            padding: 10px;
          }
        }
      }

    }
  }
}