.main-wrapper {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/workshop/background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .inner-wrapper {
    width: 60%;
    height: 100%;
    margin: 0 auto;

    .title-wrapper {
      background-image: url('../../assets/workshop/title-background.svg');
      background-repeat: no-repeat;
      background-size: contain;
      font-size: 30px;
      color: #fff;
      width: 40%;
      height: 300px;
      letter-spacing: 4px;
      position: relative;

      .title-inner-wrapper {
        position: absolute;
        top: 80px;
        left: 70px;

        .sub-title {
          font-size: 35px;
          color: #e04c26;
        }
      }

    }

    .content {
      color: #fff;
      margin: 0 50px;
      letter-spacing: 2px;

      .workshops-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 50px 0;

        .workshop-wrapper {
          padding: 20px 40px;
          box-sizing: border-box;
          background-color: #fff;
          color: #000;
          width: 40%;

          .title {
            color: #e04c26;
            font-size: 25px;

          }

          .desc {
            margin-top: 10px;
          }

          .ps {
            margin-top: 10px;
            font-size: 12px;
          }
        }
      }

      .text-center {
        text-align: center;
      }

      .btn-register {
        width: 100px;
        height: 100px;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .main-wrapper {

    .inner-wrapper {
      width: 80%;
      display: flex;
      flex-wrap: wrap;

      .title-wrapper {
        align-self: flex-end;
        height: 200px;
        width: 60%;

        .title-inner-wrapper {

          .sub-title {}
        }

      }

      .content {

        .workshops-wrapper {
          flex-wrap: wrap;
          margin: 10px 0;

          .workshop-wrapper {
            flex: 1 0 100%;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .title {
              font-size: 20px;
            }

            .desc {}

            .ps {}
          }
        }

        .text-center {}

        .btn-register {}
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    // height: calc(100vh - 60px);
    height: 100%;
    padding-top: 60px;
    background-repeat: unset;

    .inner-wrapper {
      width: 100%;

      .title-wrapper {
        width: 80%;
        margin-top: 50px;
        height: 200px;
        font-size: 25px;

        .title-inner-wrapper {
          top: 60px;
          left: 50px;

          .sub-title {
            font-size: 30px;
          }
        }

      }

      .content {
        font-size: .8rem;
        margin: 0 20px;
        padding-bottom: 50px;

        .workshops-wrapper {
          flex-wrap: wrap;
          margin: 10px 0;

          .workshop-wrapper {
            flex: 1 0 100%;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .title {
              font-size: 20px;
            }

            .desc {}

            .ps {}
          }
        }

        .text-center {}

        .btn-register {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}