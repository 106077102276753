.main-wrapper {
  background-image: url("../../assets/pc_game2_bg.jpg");
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: top center;

  .title-wrapper {
    height: 20%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }

  .chatbox-wrapper {
    position: absolute;
    height: 65%;
    left: 60%;
    top: 20%;

    .chatbox-inner-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      color: #ffffff;
      letter-spacing: 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding: 30% 10% 10% 10%;

      .message-wrapper {
        width: 100%;

        .message {
          line-height: 1.5em;
          font-size: 20px;
        }

        .desc {
          font-size: 16px;
          margin-top: 10px;
        }

        span {
          color: #F04922;
          font-size: 1.5em;
        }
      }

      .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        flex: 1 0 120%;
        max-width: 100%;

        .button-wrapper {
          background-repeat: no-repeat;
          width: 80px;
          height: 80px;
          background-size: contain;

          &.btn-book {
            background-image: url("../../assets/btn-book.png");
          }

          &.btn-workshop {
            background-image: url("../../assets/btn-workshop.png");
          }

          &.btn-web {
            background-image: url("../../assets/btn-web.png");
          }

          &.btn-video {
            background-image: url("../../assets/btn-video.png");
          }

          &.btn-restart {
            background-image: url("../../assets/btn-restart.png");
          }

          &.btn-forum {
            background-image: url("../../assets/btn-forum.png");
          }
        }

      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .main-wrapper {


    .title-wrapper {}

    .chatbox-wrapper {
      left: 40%;

      .chatbox-inner-wrapper {

        .message-wrapper {

          .message {}

          .desc {}

          span {}
        }


        .buttons-wrapper {

          .button-wrapper {

            &.btn-book {}

            &.btn-workshop {}

            &.btn-web {}

            &.btn-video {}

            &.btn-restart {}
          }

        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-wrapper {


    .title-wrapper {}

    .chatbox-wrapper {
      left: 30%;

      .chatbox-inner-wrapper {

        .message-wrapper {

          .message {}

          .desc {}

          span {}
        }


        .buttons-wrapper {

          .button-wrapper {

            &.btn-book {}

            &.btn-workshop {}

            &.btn-web {}

            &.btn-video {}

            &.btn-restart {}
          }

        }
      }
    }
  }
}


@media screen and (max-width: 640px) {
  .main-wrapper {
    background-image: url("../../assets/m_game2_bg.png");
    height: calc(100vh - 60px);
    margin-top: 60px;
    overflow: hidden;
    position: relative;

    .title-wrapper {
      height: 10%;
    }

    .chatbox-wrapper {
      height: unset;
      width: 100%;
      right: -30%;
      top: 30%;
      left: unset;

      .chatbox-inner-wrapper {
        padding: 50px 30% 10px 0px;
        letter-spacing: 4px;

        .message-wrapper {
          padding: 0 0 0 40px;

          .message {
            font-size: 12px;
          }

          .desc {
            font-size: 12px;
          }

          span {}
        }


        .buttons-wrapper {
          flex-wrap: wrap;
          justify-content: center;
          flex: 1 0 120%;

          .button-wrapper {
            width: 50px;
            height: 50px;
            margin: 0 10px;

            &.btn-book {}

            &.btn-workshop {}

            &.btn-web {}

            &.btn-video {}

            &.btn-restart {}
          }

        }
      }
    }
  }
}