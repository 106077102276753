.main-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 200;

  .inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60%;
    height: 70%;
    font-size: 20px;
    letter-spacing: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .center-wrapper {
      height: 100%;

      .title-wrapper {
        background-image: url('../../assets/credit/title-background.svg');
        background-repeat: no-repeat;
        background-size: contain;
        font-size: 30px;
        color: #fff;
        width: 50%;
        height: 200px;
        letter-spacing: 4px;
        position: relative;

        .title-inner-wrapper {
          position: absolute;
          top: 40px;
          left: 70px;

          .orange {
            color: #e04c26;
          }
        }
      }

      .content-wrapper {
        margin-left: 200px;
        margin-top: 20px;

        .credit-wrapper {
          display: flex;

          &.space {
            margin-top: 20px;
          }

          .title {
            color: #e04c26;
          }

          .text {
            color: #fff;

            .link {
              font-size: 16px;
            }
          }
        }
      }

      .btn-close {
        margin: 0 auto;
        width: 40px;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .main-wrapper {

    .inner-wrapper {
      width: 100%;

      .center-wrapper {

        .title-wrapper {

          .orange {}
        }

        .content-wrapper {

          .credit-wrapper {

            &.space {}

            .title {}

            .text {

              .link {}
            }
          }
        }

        .btn-close {}
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    height: calc(100vh - 60px);
    margin-top: 60px;

    .inner-wrapper {
      width: 90%;
      height: 80%;
      font-size: 16px;

      .center-wrapper {

        .title-wrapper {
          width: 70%;
          font-size: 20px;
          height: 150px;

          .orange {}
        }

        .content-wrapper {
          margin-left: unset;

          .credit-wrapper {

            &.space {}

            .title {}

            .text {

              .link {
                font-size: 14px;
              }
            }
          }
        }

        .btn-close {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .main-wrapper {

    .inner-wrapper {

      .center-wrapper {

        .title-wrapper {
          height: 100px;

          .orange {}
        }

        .content-wrapper {

          .credit-wrapper {

            &.space {}

            .title {}

            .text {

              .link {}
            }
          }
        }

        .btn-close {}
      }
    }
  }
}