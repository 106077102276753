.main-wrapper {
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    height: calc(100vh - 60px);
    margin-top: 60px;
  }
}
